import Box from "../../../../components/Box";
import Grid from "@mui/material/Grid";
import { Radio, RadioGroup } from "@mui/material";
import { answerRick } from "../default-values/question-answers";
import { useLang } from "../../../../hooks/useLang";
import { RedRadio } from "../styled/RedRadio";
import { FormControlBlueLabel } from "../styled/FormControlBlueLabel";
import { FormControlRedLabel } from "../styled/FormControlRedLabel";

const Risks = ({ values, setFieldValue }) => {
  const { language } = useLang();

  if (values.length === 0) {
    values.push({ title: answerRick[language]['Threat'], right: true })
    values.push({ title: answerRick[language]['Opportunity'], right: false })
  }

  return (
    <Box pt={ 4 } pb={ 3 } px={ 3 }>
      <Grid container spacing={ 3 }>
        <RadioGroup
          onChange={(e) => {
            setFieldValue(e.target.name, e.target.checked);
            setFieldValue(`answers[${e.target.value}].right`, !e.target.checked);
          }}
        >
          <FormControlRedLabel sx={{ color: 'green' }} value={1} name="answers[0].right" control={<RedRadio />} label={answerRick[language]['Threat']} checked={values[0].right}/>
          <FormControlBlueLabel value={0} name="answers[1].right" control={<Radio />} label={answerRick[language]['Opportunity']} checked={values[1].right}/>
        </RadioGroup>
      </Grid>
    </Box>
  )
}

export default Risks;