import { styled } from "@mui/material/styles";
import { FormControlLabel } from "@mui/material";
import { red } from "@mui/material/colors";

export const FormControlRedLabel = styled((props) => (
    <FormControlLabel {...props} />
))(() => ({
    ".MuiFormControlLabel-label": {
        color: red[800],
    }
}));
