import {styled} from "@mui/material/styles";
import {Radio} from "@mui/material";
import {red} from "@mui/material/colors";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import linearGradient from "../../../../assets/theme/functions/linearGradient";

export const RedRadio = styled((props) => (
    <Radio {...props} />
))(({theme}) => ({
    "&:after": {
        transition: "opacity 250ms ease-in-out",
        content: `""`,
        position: "absolute",
        width: pxToRem(14),
        height: pxToRem(14),
        borderRadius: "50%",
        backgroundImage: linearGradient(red[600], red[600]),
        opacity: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
    },
    "&.Mui-focusVisible": {
        border: `${theme.borders.borderWidth[1]} solid ${red[600]} !important`,
    },
    "&.Mui-checked": {
        color: red[600],

        "& .MuiSvgIcon-root": {
            borderColor: red[800],
        },

        "&:after": {
            opacity: 1,
        },
    },
}));
